<template>
  <tableau view="PLpracticesinsur/sheet0" />
</template>

<script>
import Tableau from "./Tableau"

export default {
  components: { Tableau }
}
</script>
